import { useState } from 'react'
import { graphql } from 'gatsby'
import { ButtonStack, Button, Column } from '@dfds-ui/react-components'
import { FlexCard } from '../Card'
import { Markdown } from '../Markdown'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { EntryLink } from '../EntryLink'

const StyledButtonStack = styled(ButtonStack)`
  margin-left: 30px;
  margin-bottom: 20px;
`

const StyledButton = styled(Button)`
  margin-right: 10px;
`

const PageContentTextWithLanguageSwitcher = (props) => {
  const {
    language1ButtonText,
    language2ButtonText,
    loginButtonTextLanguage1,
    loginButtonTextLanguage2,
    loginButtonUrlLanguage1,
    loginButtonUrlLanguage2,
    textLanguage1,
    textLanguage2,
    linkCollection,
    linkLanguage2Collection,
    blockWidth,
  } = props
  const [activeLanguage, setActiveLanguage] = useState(language1ButtonText)

  return (
    <>
      <Column m={12}>
        <LanguageSelectionButtonStack
          language1={language1ButtonText}
          language2={language2ButtonText}
          activeLanguage={activeLanguage}
          setActiveLanguage={setActiveLanguage}
        />
      </Column>
      <LanguageContent
        content={textLanguage1}
        activeLanguage={activeLanguage}
        contentLanguage={language1ButtonText}
        buttonText={loginButtonTextLanguage1}
        buttonUrl={loginButtonUrlLanguage1}
        links={linkCollection}
        width={blockWidth}
      />
      <LanguageContent
        content={textLanguage2}
        activeLanguage={activeLanguage}
        contentLanguage={language2ButtonText}
        buttonText={loginButtonTextLanguage2}
        buttonUrl={loginButtonUrlLanguage2}
        links={linkLanguage2Collection}
        width={blockWidth}
      />
    </>
  )
}

const LanguageSelectionButtonStack = ({
  language1,
  language2,
  activeLanguage,
  setActiveLanguage,
}) => (
  <StyledButtonStack>
    <LanguageSelectionButton
      language={language1}
      activeLanguage={activeLanguage}
      setActiveLanguage={setActiveLanguage}
    />
    <LanguageSelectionButton
      language={language2}
      activeLanguage={activeLanguage}
      setActiveLanguage={setActiveLanguage}
    />
  </StyledButtonStack>
)

const LanguageSelectionButton = ({
  language,
  activeLanguage,
  setActiveLanguage,
}) => (
  <StyledButton
    variation={activeLanguage === language ? 'secondary' : 'outlined'}
    onClick={() => setActiveLanguage(language)}
  >
    {language}
  </StyledButton>
)

const LanguageContent = ({
  content,
  activeLanguage,
  contentLanguage,
  buttonText,
  buttonUrl,
  links,
  width,
}) => (
  <FlexCard
    hide={activeLanguage !== contentLanguage}
    width={width}
    action={
      buttonText && (
        <Button
          as={'a'}
          href={buttonUrl}
          target="_blank"
          css={css`
            color: white !important;
          `}
        >
          {buttonText}
        </Button>
      )
    }
  >
    <Markdown source={content} />
    {links?.items.map((link, i) => (
      <EntryLink key={i} {...link} />
    ))}
  </FlexCard>
)

export const query = graphql`
  fragment PageContentTextWithLanguageSwitcher on contentful_PageContentTextWithLanguageSwitcher {
    __typename
    sys {
      id
    }
    language1ButtonText
    language2ButtonText
    loginButtonTextLanguage1
    loginButtonTextLanguage2
    loginButtonUrlLanguage1
    loginButtonUrlLanguage2
    textLanguage1
    textLanguage2
    linkCollection(limit: 1) {
      items {
        ...ItemLink
      }
    }
    linkLanguage2Collection(limit: 1) {
      items {
        ...ItemLink
      }
    }
    blockWidth
  }
`

export default PageContentTextWithLanguageSwitcher
